<template>
	<div v-if="authed">
		<v-data-table :headers="headers" :items="items" item-key="_id"
			:options.sync="options" :server-items-length="totalItems"
			:loading="loading" loading-text="加载中...">
			<template v-slot:top>
				<div class="d-flex flex-wrap pt-4 pl-4" style="gap:8px;">
					<v-select label="订单类型" persistent-placeholder placeholder="全部" clearable :items="otfilters" v-model="otfilter" dense style="flex: 0 1 auto;max-width:130px;"/>
					<v-select label="支付方" persistent-placeholder placeholder="全部" clearable :items="['自费','保险']" v-model="pwfilter" dense style="flex: 0 1 auto;max-width:100px;"/>
					<v-select label="筛选" :items="['选择产品','确认付款','提交健康问卷','确认检查单','评估完成','手术时间']" v-model="cond" dense style="flex: 0 1 auto;max-width:140px;"/>
					<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
						transition="scale-transition" offset-y min-width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="pdate1" label="日期起" readonly v-bind="attrs"
								v-on="on" dense style="flex: 0 1 auto;max-width:120px;"></v-text-field>
						</template>
						<v-date-picker v-model="pdate1" @input="menu1 = false" no-title scrollable></v-date-picker>
					</v-menu>
					<v-select label="时间起" v-model="ptime1" :items="['00:00','09:00','10:00','16:00']" dense style="flex: 0 1 auto;max-width:120px;"/>
					<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="10"
						transition="scale-transition" offset-y min-width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="pdate2" label="日期止" readonly v-bind="attrs"
								v-on="on" dense style="flex: 0 1 auto;max-width:120px;"></v-text-field>
						</template>
						<v-date-picker v-model="pdate2" @input="menu2 = false" no-title scrollable></v-date-picker>
					</v-menu>
					<v-select label="时间止" v-model="ptime2" :items="['08:59','09:59','15:59','23:59']" dense style="flex: 0 1 auto;max-width:120px;"/>
					<v-text-field v-model="pname" label="客户姓名" clearable dense style="flex: 0 1 auto;max-width:100px;"></v-text-field>
					<v-btn color="primary" @click="fetchData" class="mx-1">查询</v-btn>
					<v-btn v-if="canExport" color="primary" @click="exportXLS" class="mx-1">导出</v-btn>
				</div>
				<v-divider/>
			</template>
				<template v-slot:item.orderSource="props">
					<v-edit-dialog :return-value.sync="props.item.orderSource" large
						save-text="保存" cancel-text="取消" @save="saveItem('orderSource', props)">
					{{props.item.orderSource}}
						<template v-slot:input>
							<v-select label="渠道" v-model="props.item.orderSource" :items="orderSources"/>
						</template>
					</v-edit-dialog>
				</template>
				<template v-slot:item.payment.insurance="props">
					<v-edit-dialog :return-value.sync="props.item.payment.insurance" large
						save-text="保存" cancel-text="取消" @save="saveInsurance(props)">
					{{props.item.payment.insurance && props.item.payment.insurer ? `${props.item.payment.insurer} - ${props.item.payment.insurance}` : '自费'}}
						<template v-slot:input>
							<v-select label="支付方式" persistent-placeholder placeholder="自费" v-model="props.item.payment.insurance" :items="payways" clearable/>
							<v-combobox label="保险机构" persistent-placeholder placeholder="无" v-model="props.item.payment.insurer" :items="getInsurers(props.item.payment.insurance)" clearable :disabled="!props.item.payment.insurance"/>
						</template>
					</v-edit-dialog>
				</template>
				<template v-slot:item.opts.PRE="props">
					<!--
					<span v-if="props.item.opts.PRE==='华兆' && !props.item.HzPre" class="text-decoration-underline" style="color:#03A9F4;cursor:pointer;" @click.stop="onPre(props.item)">{{props.item.opts.PRE}}</span>
					-->
					<v-edit-dialog :return-value.sync="props.item.opts.PRE" large
						save-text="保存" cancel-text="取消" @save="saveItem('opts.PRE', props)">
						{{props.item.opts.PRE}}
						<template v-slot:input>
							<v-select :items="['华兆','华三','外院']" v-model="props.item.opts.PRE"/>
						</template>
					</v-edit-dialog>
				</template>
				<template v-slot:item.pii.username="props">
					<v-edit-dialog :return-value.sync="props.item.pii.username" large
						save-text="保存" cancel-text="取消" @save="saveItem('pii.username', props)">
					{{props.item.pii.username}}
						<template v-slot:input>
							<v-text-field label="姓名" v-model="props.item.pii.username" :disabled="!!props.item.anesEvalDate"/>
						</template>
					</v-edit-dialog>
				</template>
				<template v-slot:item.pii.phone="props">
					<v-edit-dialog :return-value.sync="props.item.pii.phone" large
						save-text="保存" cancel-text="取消" @save="saveItem('pii.phone', props)">
					{{props.item.pii.phone}}
						<template v-slot:input>
							<v-text-field label="电话" v-model="props.item.pii.phone"/>
						</template>
					</v-edit-dialog>
				</template>
				<template v-slot:item.QA.baseinfo.bodyheight="props">
					<v-edit-dialog v-if="props.item.QA && props.item.QA.baseinfo" :return-value.sync="props.item.QA.baseinfo.bodyheight" large
						save-text="保存" cancel-text="取消" @save="saveItem('QA.baseinfo.bodyheight', props)">
					{{props.item.QA.baseinfo.bodyheight}}
						<template v-slot:input>
							<v-text-field label="身高" v-model="props.item.QA.baseinfo.bodyheight"/>
						</template>
					</v-edit-dialog>
				</template>
				<template v-slot:item.QA.baseinfo.bodyweight="props">
					<v-edit-dialog v-if="props.item.QA && props.item.QA.baseinfo" :return-value.sync="props.item.QA.baseinfo.bodyweight" large
						save-text="保存" cancel-text="取消" @save="saveItem('QA.baseinfo.bodyweight', props)">
					{{props.item.QA.baseinfo.bodyweight}}
						<template v-slot:input>
							<v-text-field label="体重" v-model="props.item.QA.baseinfo.bodyweight"/>
						</template>
					</v-edit-dialog>
				</template>
				<template v-slot:item.bmi="{ item }">
					<span :style="{color:getBMI(item) >30 ? 'red':'black'}">{{getBMI(item)}}</span>
				</template>
				<template v-slot:item.idcard="{ item }">
					{{getMaskedIdcard(item)}}
				</template>
				<template v-slot:item.age="{ item }">
					{{getAge(item)}}
				</template>
				<template v-slot:item.opts.GIE="props">
					<v-edit-dialog large :return-value.sync="props.item.opts.GIE"
						save-text="保存" cancel-text="取消" @save="saveItem('opts.GIE', props)">
						{{props.item.opts.GIE}}
						<template v-slot:input>
							<v-select v-if="canExport" :items="optstrs[0]" v-model="props.item.opts.GIE"/>
						</template>
					</v-edit-dialog>
				</template>
				<template v-slot:item.opts.ANES="props">
					<v-edit-dialog large :return-value.sync="props.item.opts.ANES"
						save-text="保存" cancel-text="取消" @save="saveItem('opts.ANES', props)">
						{{props.item.opts.ANES}}
						<template v-slot:input>
							<v-select v-if="canExport" :items="optstrs[1]" v-model="props.item.opts.ANES"/>
						</template>
					</v-edit-dialog>
				</template>
				<template v-slot:item.opts.PRO="props">
					<v-edit-dialog large :return-value.sync="props.item.opts.PRO"
						save-text="保存" cancel-text="取消" @save="saveItem('opts.PRO', props)">
						{{props.item.opts.PRO}}
						<template v-slot:input>
							<v-select v-if="canExport" :items="optstrs[2]" v-model="props.item.opts.PRO"/>
						</template>
					</v-edit-dialog>
				</template>
				<template v-slot:item.opts.CHARGE="props">
					<v-edit-dialog large :return-value.sync="props.item.opts.CHARGE"
						save-text="保存" cancel-text="取消" @save="saveItem('opts.CHARGE', props)">
						{{props.item.opts.CHARGE}}
						<template v-slot:input>
							<v-select v-if="canExport" :items="['前台付款','统一结算']" v-model="props.item.opts.CHARGE"/>
						</template>
					</v-edit-dialog>
				</template>
				<template v-slot:item.consultantName="props">
					<v-edit-dialog large :return-value.sync="props.item.consultantName"
						save-text="保存" cancel-text="取消" @save="saveConsultant(props)">
						{{props.item.consultantName}}
						<template v-slot:input>
							<v-select v-if="isConsultant" :items="cnslts" item-text="name" item-value="name" v-model="props.item.consultantName"/>
						</template>
					</v-edit-dialog>
				</template>
				<template v-slot:item.progress.order="{ item }">
					{{formatTime(item.progress.order)}}
				</template>
				<template v-slot:item.progress.evalConclusion="{ item }">
					{{formatTime(item.progress.evalConclusion)}}
				</template>
				<template v-slot:item.progress.operative="{ item }">
					{{formatTime(item.progress.operative)}}
				</template>
					<template v-slot:footer>
						<div class="pt-3" style="float:left">
							<v-icon large class="ml-4" @click.stop="addItem">mdi-plus</v-icon>
						</div>
					</template>
				</v-data-table>
				<v-dialog v-model="dlg" width="500">
					<v-card>
						<v-card-title>新建后台订单</v-card-title>
						<v-card-text>
							<v-form ref="formNew">
			                    <v-container>
									<v-row dense>
										<v-col cols="6"><v-select dense label="套餐选项1" :items="optstrs[0]" v-model="neworder.opts.GIE"/></v-col>
										<v-col cols="6"><v-select dense label="套餐选项2" :items="optstrs[1]" v-model="neworder.opts.ANES"/></v-col>
									</v-row>
									<v-row dense>
										<v-col cols="6"><v-select dense label="套餐选项3" :items="optstrs[2]" v-model="neworder.opts.PRO"/></v-col>
										<v-col cols="6"><v-text-field dense label="价格" v-model.number="neworder.price" :rules="numberRules"/></v-col>
									</v-row>
									<v-row dense>
										<v-col cols="6"><v-text-field dense label="客户姓名" v-model.trim="neworder.pii.username" :rules="cnltRules"/></v-col>
										<v-col cols="6"><v-text-field dense label="手机号" v-model.trim="neworder.pii.phone" :rules="phoneRules"/></v-col>
									</v-row>
									<v-row dense>
										<v-col cols="4"><v-select dense label="证件类型" :items="['身份证','护照','军官证','其他']" v-model="neworder.pii.idtype" @change="parseIdcard"/></v-col>
										<v-col cols="8"><v-text-field dense label="证件号码" v-model.trim="neworder.pii.idcard" :rules="cnltRules" :error-messages="iderr ? '身份证号无效' : ''" @input="parseIdcard"/></v-col>
									</v-row>
									<v-row dense>
										<v-col cols="4"><v-select dense label="性别" :items="['男','女']" v-model="neworder.pii.gender" :rules="cnltRules" :readonly="neworder.pii.idtype==='身份证'"/></v-col>
										<v-col cols="8">
											<v-menu v-model="menuDob" :close-on-content-click="false" :nudge-right="10"
												transition="scale-transition" offset-y min-width="290px">
												<template v-slot:activator="{ on, attrs }">
													<v-text-field v-model="neworder.pii.dob" label="出生日期" readonly v-bind="attrs"
														v-on="on" dense :rules="cnltRules"></v-text-field>
												</template>
												<v-date-picker v-model="neworder.pii.dob" @change="menuDob=false" no-title scrollable :readonly="neworder.pii.idtype==='身份证'"></v-date-picker>
											</v-menu>
										</v-col>
									</v-row>
									<v-row dense>
										<v-col cols="4"><v-select dense label="健康顾问" :items="cnslts" item-text="name" item-value="_id" return-object v-model="neworderconsultant"
											:rules="cnltRules" :readonly="!canExport"/></v-col>
										<v-col cols="8"><v-combobox dense label="订单渠道" :items="orderSources" v-model="neworder.orderSource" :rules="cnltRules"/></v-col>
									</v-row>
									<v-row dense>
										<v-col cols="4"><v-select dense label="支付方式" persistent-placeholder placeholder="自费" :items="payways" v-model="neworder.payment.insurance" clearable/></v-col>
										<v-col cols="8"><v-select dense label="保险机构" persistent-placeholder placeholder="无" :items="insurers" v-model="neworder.payment.insurer" clearable :disabled="!neworder.payment.insurance"/></v-col>
									</v-row>
									<v-row dense>
										<v-col cols="4"><v-text-field label="实付金额" v-model.number="neworder.payment.actual" :rules="numberRules" dense/></v-col>
										<v-col cols="8"><v-text-field label="付款备注" v-model="neworder.payment.comment" dense/></v-col>
									</v-row>
			                    </v-container>
							</v-form>
						</v-card-text>
						<v-divider/>
						<v-card-actions>
							<v-spacer/>
							<v-btn color="primary" :loading="loading" @click.stop="saveNew">确定</v-btn>
							<v-btn @click.stop="dlg=false">取消</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
	import XLSX from 'xlsx-js-style'
	import {formatTime, formatDate, calculateAge, nowOffsetHours, checkIdCard} from '../utils'
	import store from '../store.js'

    export default {
        data() {
            return {
				isConsultant: false,
				canExport: false,
                authed: false,
				pdate1: '',
				ptime1: '',
				menu1: false,
				pdate2: '',
				ptime2: '',
				menu2: false,
				pname: '',
                headers: [
                    {text:'订单号', value:'_id', width:120},
                    {text:'病案号', value:'pid', width:100},
                    {text:'姓名', value:'pii.username', width:120},
                    {text:'性别', value:'pii.gender', width:80},
                    {text:'出生日期', value:'pii.dob', width:120},
                    {text:'电话', value:'pii.phone', width:100},
                    {text:'证件类型', value:'pii.idtype', width:100},
                    {text:'证件号', value:'idcard', width:150},
                    {text:'年龄', value:'age', width:80},
                    {text:'身高', value:'QA.baseinfo.bodyheight', width:80},
                    {text:'体重', value:'QA.baseinfo.bodyweight', width:80},
                    {text:'BMI', value:'bmi', width:80},
                    {text:'优惠选项', value:'payment.coupontext', width:100},
                    {text:'渠道', value:'orderSource', width:80},
                    {text:'支付方', value:'payment.insurance', width:150},
                    {text:'套餐项1', value:'opts.GIE', width:120},
                    {text:'套餐项2', value:'opts.ANES', width:100},
                    {text:'套餐项3', value:'opts.PRO', width:100},
                    {text:'术中费用', value:'opts.CHARGE', width:120},
                    {text:'术前', value:'opts.PRE', width:90},
                    {text:'健康顾问', value:'consultantName', width:100},
                    {text:'下单时间', value:'progress.order', width:180},
					{text:'评估完成', value:'progress.evalConclusion', width:180},
                    {text:'手术时间', value:'progress.operative', width:180},
                    {text:'手术医生', value:'endoscopyResult.doctorT', width:100},
                    {text:'订单类型', value:'orderType', width:120},
                ],
                items: [],
				loading: false,
				cond: '选择产品',
				options: {},
				totalItems: 0,
				optstrs: [
					['胃肠镜', '胃镜', '肠镜'],
					['无痛', '普通'],
					['专家精查', '标准精查', '健康精查T', '健康精查Z']
				],
				dlg: false,
				neworder: {
					orderType: '后台',
					opts: {
						GIE: '胃肠镜',
						ANES: '无痛',
						PRO: '专家精查'
					},
					pii: {
						idtype: '身份证'
					},
					region: '北京丰联',
					payment: {way: '线下'},
					QA: {
						baseinfo: {}
					},
				},
				cnslts: [],
				neworderconsultant: null,
				cnltRules: [v => !!v || '此项为必填'],
				phoneRules: [v => (/^[1][3,4,5,6,7,8,9][0-9]{9}$/).test(v) || '手机号格式不正确'],
				numberRules: [v => (/^-?[0-9]+(.[0-9]*)?$/g).test(v) || '只允许数字'],
				menuDob: false,
				iderr: false,
				orderSources: ['常规', '定向'],
				otfilter: '',
				otfilters: ['小程序', '订单助手', '后台'],
				payways: [{text:'保险直付',value:'直付'}, {text:'保险自付',value:'自付'}],
				insurers: [],
				insurers2: [],
				pwfilter: '',
            }
        },
        mounted() {
			this.isConsultant = this.$hasPrivilege('健康顾问');
			this.canExport = this.$hasPrivilege('商务');
			this.authed = this.isConsultant||this.canExport;
			if (!this.authed) return;
			this.formatTime = formatTime;
			this.pdate1 = formatDate(Date.now() + nowOffsetHours * 3600 * 1000 - 30 * 86400 * 1000);
			this.pdate2 = formatDate(Date.now() + nowOffsetHours * 3600 * 1000);
			this.ptime1 = '09:00';
			this.ptime2 = '15:59';
			this.$watch(()=>store.currentRegion, this.fetchData);
            const db = this.$tcbapp.database();
            db.collection('workset').doc('current').get().then(res => {
				this.insurers = res.data[0].insurerlist;
				this.insurers2 = res.data[0].insurerlist.concat(res.data[0].insurerlist2);
            }).catch(console.error);
			this.fetchData();
			this.fetchConsultants();
        },
        methods: {
			async fetchData() {
				const filter = this.getFilter();
				const db = this.$tcbapp.database();
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2order').where(filter).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(filter).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			getFilter() {
				const t1 = new Date(this.pdate1 + 'T' + this.ptime1 + ':00+08:00');
				const t2 = new Date(this.pdate2 + 'T' + this.ptime2 + ':59+08:00');
				const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = {};
				if (this.otfilter) {
					filter['orderType'] = this.otfilter;
				}
				if (this.pwfilter === '自费') {
					filter['payment.insurance'] = _.exists(false);
				} else if (this.pwfilter === '保险') {
					filter['payment.insurance'] = _.exists(true);
				}
				if (this.cond === '选择产品') {
					filter['progress.order'] = _.gte(t1).lte(t2)
				} else if (this.cond === '确认付款') {
					filter['progress.payment'] = _.gte(t1).lte(t2)
				} else if (this.cond === '提交健康问卷') {
					filter['progress.qa'] = _.gte(t1).lte(t2);
					filter['examlist'] = _.exists(false);
				} else if (this.cond === '确认检查单') {
					filter['progress.eeaccepted'] = _.gte(t1).lte(t2)
				} else if (this.cond === '评估完成') {
					filter['progress.evalConclusion'] = _.gte(t1).lte(t2)
				} else {
					filter['progress.operative'] = _.gte(t1).lte(t2)
				}
				if (this.$hasPrivilege('商务')) {
					//admin也有此权限
				} else if (this.$hasPrivilege('健康顾问')) {
					const u = this.$getCurrentUser();
					filter['consultantId'] = u.consultantId;
				}
				if (this.pname) {
					filter['pii.username'] = this.pname;
				}
				filter.hide = _.neq(true);
				filter.category = _.neq('clinic');
				filter.region = store.currentRegion;
				return filter;
			},
			getInsurers(p) {
				if (p === '直付') {
					return this.insurers;
				} else if (p === '自付') {
					return this.insurers2;
				} else {
					return [];
				}
			},
			getMaskedIdcard(item) {
				return item.pii.idcard;
			},
            getAge(item) {
                return calculateAge(item.pii.dob, item.progress.order);
            },
            getOptsStr(item) {
				if (!item.opts) return null;
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO;
			},
			getBMI(item) {
				if (!item.QA || !item.QA.baseinfo || !item.QA.baseinfo.bodyheight) return '-';
				const h = item.QA.baseinfo.bodyheight * 0.01;
				const bmi = item.QA.baseinfo.bodyweight / (h * h);
				return bmi.toFixed(1);
			},
			parseIdcard() {
				this.iderr = false;
				if (this.neworder.pii.idtype==='身份证' && checkIdCard(this.neworder.pii.idcard)) {
					const idcard = this.neworder.pii.idcard;
					this.neworder.pii.dob = idcard.substr(6,8).replace(/(.{4})(.{2})/,"$1-$2-");
					this.neworder.pii.gender = idcard[16] % 2 === 1 ? '男' : '女';
				}
			},
			async saveItem(key, props) {
				await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'editInfo',
					data:{
						id: props.item._id,
						key,
						value: props.value
					}
				}});
			},
			async saveConsultant(props) {
				const v = this.cnslts.find(x => x.name === props.value);
				let data = {toConsultantId: v._id, orders: [props.item._id]};
				await this.$tcbapp.callFunction({name:"wp2mp", data:{
					funcname:'changeConsultant',
					data
				}});
			},
			async saveInsurance(props) {
				if (!props.item.payment.insurance || !props.item.payment.insurer) {
					props.item.payment.insurance = undefined;
					props.item.payment.insurer = undefined;
				}
				const id = props.item._id;
				await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'editInfo',
					data:{ id, key:'payment.insurance', value:props.item.payment.insurance }
				}});
				await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'editInfo',
					data:{ id, key:'payment.insurer', value:props.item.payment.insurer }
				}});
			},
			async fetchConsultants() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				const filter = {rank:_.gt(0)};
				if (this.$hasPrivilege('商务')) {
					//admin也有此权限
				} else if (this.$hasPrivilege('健康顾问')) {
//					const u = this.$getCurrentUser();
//					filter['_id'] = u.consultantId;
				}
				try {
					const res = await db.collection('consultants').where(filter).orderBy('rank', 'desc').field({name:true,phone:true}).get();
					this.cnslts = res.data;
					if (this.cnslts.length === 1) this.neworderconsultant = this.cnslts[0];
				} catch(err) {
					console.error(err);
				}
			},
			addItem() {
				this.dlg = true;
				this.$nextTick(()=>this.$refs.formNew.resetValidation());
			},
			async saveNew() {
				this.$refs.formNew.validate();
				if (!this.$refs.formNew.validate()) return;
				if (this.neworder.pii.idtype==='身份证' && !checkIdCard(this.neworder.pii.idcard)) {
					this.iderr = true;
					return;
				}
				this.neworder.consultantId = this.neworderconsultant._id;
				this.neworder.consultantName = this.neworderconsultant.name;
				this.neworder.consultantPhone = this.neworderconsultant.phone;
				this.neworder.payment.amount = this.neworder.price;
				if (!this.neworder.payment.insurance) {
					this.neworder.payment.insurance = undefined;
					this.neworder.payment.insurer = undefined;
				}
				this.loading = true;
				try {
					const r = await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'createOrderN',
						data: this.neworder
					}});
					this.neworder = this.$options.data().neworder;
					this.dlg = false;
					await this.fetchData();
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			async exportXLS() {
				const header = [[
					'订单号',
					'病案号',
					'姓名',
					'性别',
					'出生日期',
					'电话号码',
					'证件名称',
					'证件号码',
					'地址',
					'检查项目',
					'支付方',
					'应付额',
					'实付',
					'付款方式',
					'付款日期',
					'术前',
					'客源',
					'销售顾问',
					'手术日期',
					'手术医生',
					'优惠选项',
				]];
				const MAX_LIMIT = 100;
				const filter = this.getFilter();
				const db = this.$tcbapp.database();
				const countResult = await db.collection('wp2order').where(filter).count();
				const total = countResult.total;
				const batchTimes = Math.ceil(total / MAX_LIMIT);
				const tasks = [];
				for (let i = 0; i < batchTimes; i++) {
					const promise = db.collection('wp2order').where(filter).skip(i * MAX_LIMIT).limit(MAX_LIMIT).get();
					tasks.push(promise);
				}
				const res = (await Promise.all(tasks)).reduce((acc, cur) => {
					return {
						data: acc.data.concat(cur.data),
						errMsg: acc.errMsg,
					}
				});
				const data = res.data.map(x => [
					x._id,
					x.pid,
					x.pii.username,
					x.pii.gender,
					x.pii.dob,
					x.pii.phone,
					x.pii.idtype,
					x.pii.idcard,
					'',
					this.getOptsStr(x),
					(x.payment?.insurance && x.payment?.insurer) ? `${x.payment.insurer} - ${x.payment.insurance}` : '自费',
					x.price || '',
					x.payment?.actual || '',
					x.payment?.way || '',
					formatDate(x.progress.payment),
					x.opts.PRE || '',
					x.orderSource || '',
					x.consultantName,
					formatDate(x.progress.operative),
					x.endoscopyResult?.doctorT || '',
					x.payment.coupontext || '',
				]);
				const t = this.pdate1 + '~' + this.pdate2;
				const filename = t + ".xlsx";
				const ws_name = t;
				const wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(header.concat(data));
				XLSX.utils.book_append_sheet(wb, ws, ws_name);
				XLSX.writeFile(wb, filename);
			}
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			}
		}
    }
</script>
